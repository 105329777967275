import React, { useContext } from 'react'

import StoreContext from '../../context/StoreContext'
import LineItem from './LineItem'
import { Link } from 'gatsby'

const Cart = () => {
  const context = useContext(StoreContext)
  const { checkout } = context

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  const line_items = checkout.lineItems.map(line_item => {
    return <LineItem key={line_item.id.toString()} line_item={line_item} />
  })

  if (line_items.length === 0) {
    return (
      <div className="block block-empty">
        <div className="container">
          <div className="block-empty__body">
            <div className="block-empty__message">
              Your shopping cart is empty!
            </div>
            <div className="block-empty__actions">
              <Link to="/shop" className="btn btn-primary btn-sm">
                Go to the Shop
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="cart block">
      <div className="container">
        <table className="cart__table cart-table">
          <thead className="cart-table__head">
            <tr className="cart-table__row">
              <th className="cart-table__column cart-table__column--image"></th>
              <th className="cart-table__column cart-table__column--product">
                Item
              </th>
              <th className="cart-table__column cart-table__column--price">
                Price
              </th>
              <th className="cart-table__column cart-table__column--quantity">
                Quantity
              </th>
              <th className="cart-table__column cart-table__column--total">
                Total
              </th>
              <th className="cart-table__column cart-table__column--remove" />
            </tr>
          </thead>
          <tbody className="cart-table__body">{line_items}</tbody>
        </table>

        {/*<div className="cart__actions">
              <form className="cart__coupon-form">
                  <label htmlFor="input-coupon-code" className="sr-only">Password</label>
                  <input type="text" className="form-control" id="input-coupon-code" placeholder="Coupon Code" />
                  <button type="submit" className="btn btn-primary">Apply Coupon</button>
              </form>
              <div className="cart__buttons">
                  <Link to="/" className="btn btn-light">Continue Shopping</Link>
                  {updateCartButton}
              </div>
            </div>*/}

        <div className="row justify-content-end pt-md-5 pt-4">
          <div className="col-12 col-md-7 col-lg-6 col-xl-5">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Basket Totals</h3>
                <table className="cart__totals">
                  <thead className="cart__totals-header">
                    <tr>
                      <th>Subtotal</th>
                      <td>&pound;{checkout.subtotalPrice}</td>
                    </tr>
                  </thead>

                  <tbody className="cart__totals-body">
                    <tr>
                      <th>VAT</th>
                      <td>&pound;{checkout.totalTax}</td>
                    </tr>
                  </tbody>

                  <tfoot className="cart__totals-footer">
                    <tr>
                      <th>Total</th>
                      <td>&pound;{checkout.totalPrice}</td>
                    </tr>
                  </tfoot>
                </table>
                <button
                  onClick={handleCheckout}
                  className="btn btn-primary btn-xl btn-block cart__checkout-button"
                >
                  Checkout
                </button>
                <div className="cart__checkout-desc">You will be transferred to Poppy's Picnic to complete your transaction.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cart
