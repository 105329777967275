import React from 'react'

import Cart from '../components/Cart'

import PageHeader from '../components/Shared/PageHeader'

const breadcrumb = [
  { title: 'Home', url: '/' },
  { title: 'Basket', url: '/basket' },
]

const CartPage = () => (
  <>
    <PageHeader header="Basket" breadcrumb={breadcrumb} />
    <Cart />
  </>
)

export default CartPage
