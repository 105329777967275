import React, { useContext } from 'react'
import { Flex, Box } from '@rebass/grid/emotion'
import { useStaticQuery, graphql, Link } from 'gatsby'

import StoreContext from '../../../context/StoreContext'
import Cross12Svg from '!svg-react-loader!../../../images/svg/cross-12.svg'

const LineItem = props => {
  const context = useContext(StoreContext)
  let { line_item } = props

  const variantImage = line_item.variant.image ? (
    <img
      src={line_item.variant.image.src}
      alt={`${line_item.title} product shot`}
      height="60px"
    />
  ) : null

  const selectedOptions = line_item.variant.selectedOptions ? (
    <div>
      {line_item.variant.selectedOptions.map(option => {
        if (option.name == 'Title' && option.value == 'Default Title')
          return false
        return `${option.name}: ${option.value} `
      })}
    </div>
  ) : null

  const handleRemove = () => {
    context.removeLineItem(context.client, context.checkout.id, line_item.id)
  }

  const data = useStaticQuery(
    graphql`
      query {
        dataJson {
          products {
            newHandle
            shopifyId
            title
          }
        }
      }
    `
  )

  const variantId = line_item.variant.product.id
  // console.log('data', line_item, data.dataJson.products)

  let meta = data.dataJson.products.filter(
    item => variantId === item.shopifyId
  )[0]
  if (meta) {
    line_item.newTitle = meta.title
    line_item.handle = meta.newHandle
  } else {
    line_item.handle = line_item.variant.product.handle
  }

  return (
    <tr className="cart-table__row">
      <td className="cart-table__column cart-table__column--image">
        <Link to={`/product/${line_item.handle}/`}>{variantImage}</Link>
      </td>
      <td className="cart-table__column cart-table__column--product">
        {/*<Link to={`/shop/product/${item.product.id}`} className="cart-table__product-name">
            {item.product.name}
        </Link>*/}
        <Link
          to={`/product/${line_item.handle}/`}
          className="cart-table__product-name"
        >
          {line_item.newTitle || line_item.title}
        </Link>
      </td>
      <td
        className="cart-table__column cart-table__column--price"
        data-title="Price"
      >
        &pound;{line_item.variant.price}
      </td>
      <td
        className="cart-table__column cart-table__column--quantity"
        data-title="Quantity"
      >
        {line_item.quantity}
      </td>
      <td
        className="cart-table__column cart-table__column--total"
        data-title="Total"
      >
        &pound;{(line_item.variant.price * line_item.quantity).toFixed(2)}
      </td>
      <td className="cart-table__column cart-table__column--remove">
        <button
          type="button"
          onClick={handleRemove}
          className="btn btn-light btn-sm btn-svg-icon"
        >
          <Cross12Svg />
        </button>
      </td>
    </tr>
  )
}

export default LineItem
